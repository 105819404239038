import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PostItem from "../components/post-item"
import Pagination from "../components/pagination"
import SEO from "../components/seo"

export default ({ data, pageContext, location }) => {
  const posts = data.allMarkdownRemark.edges
  const { pageNumber, numberOfPages } = pageContext
  return (
    <Layout location={location}>
      <SEO
        title={pageNumber === 0 ? null : `page ${pageNumber + 1}`}
        pathname={location.pathname}
      />
      {posts.map(({ node }) => {
        const {
          html,
          id,
          frontmatter: { title, tags, date, datetime },
          fields: { slug },
        } = node
        return (
          <PostItem
            key={id}
            title={title}
            date={date}
            dateTime={datetime}
            html={html}
            tags={tags}
            slug={slug}
            excerpt
          />
        )
      })}
      <Pagination
        pageNumber={pageNumber}
        numberOfPages={numberOfPages}
        basePath={`/`}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "YYYY年MM月DD日")
            datetime: date(formatString: "YYYY-MM-DD")
            tags
            title
          }
          fields {
            slug
          }
          html
          id
        }
      }
    }
  }
`
